import React, {useEffect, useState} from 'react';
import {Card, CardActions, CardContent, Paper} from '@material-ui/core';
import Header from "../common/Header";

import {useStyles} from "../../styles/MfaSetupStyles";
import {disableMfaCode, isMfaActive, setupMfaCode} from "../../api/SecurityApi";
import ButtonStyled from "../common/ButtonStyled";
import BackendError from "../common/BackendError";
import { useTranslation } from 'react-i18next';

export default function SetupForm(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [active, setActive] = useState(false);
    const [activate, setActivate] = useState(false);
    const [imageData, setImageData] = useState(null);

    useEffect(() => {
        isMfaActive()
            .then(res => {
                setActive(res);
            });
    }, []);

    function _handleSetActivate() {
        setupMfaCode()
            .then(res => {
                setImageData(res.qrCodeImage);
                setActivate(true);
                setActive(true);
            });
    }

    function _handleDisable() {
        disableMfaCode()
            .then(res => {
                setImageData(null);
                setActivate(false);
                setActive(false);
            });
    }

    return (
        <Paper className={classes.root} style={props.style}>
            <Card>
                <Header title={t("ACTIVATIONCODE")} titleTypographyProps={{variant: "h5", align: "center"}}/>
                <CardContent hidden={!activate}>
                    <div className={classes.cardContent_text}>
                        <p>{t("SCANQR")}</p>
                        <p className={classes.cardContent_text_activation}>{t("ACTIVE")}</p>
                    </div>
                    <div className={classes.cardContent_image}>
                        <img src={imageData} className={classes.qr_code}></img>
                    </div>
                </CardContent>
                <CardActions>
                    <ButtonStyled style={{margin: 'auto'}} text={t("GENERATENEW")} onClick={() => _handleSetActivate()}/>
                    {active ? <ButtonStyled style={{margin: 'auto'}} text={t("DISABLE")} onClick={() => _handleDisable()}/> : ""}
                </CardActions>
            </Card>
        </Paper>
    );
}
