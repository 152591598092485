import {makeStyles} from '@material-ui/core/styles';
import theme from "../config/Theme";
import {stylesConfig} from "../config/StylesConfig";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    root: {
        width: stylesConfig.contentWidth,
        padding: 20,
    },
    cardContent_text: {
        float: 'left',
        display: 'inline',
        width: '50%',
        height: '300px',
        padding: '30px 20px 10px 20px'
    },
    cardContent_image: {
        float: 'right',
        display: 'inline',
        width: '50%',
        height: '300px'
    },
    qr_code: {
        width: '250px',
        height: '250px'
    },
    cardContent_text_activation: {
        color: 'red'
    },
    formErrors: {
        color: theme.palette.error.main
    },
}), {defaultTheme: theme});

export {useStyles};

