import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    //ButtonStyled
    button:{
        width: 200,
        background: theme.palette.secondary.main,
        "&:hover":{
            background: theme.palette.secondary.dark,
        },
        color: 'white',
        height: 40
    },
    languageButton:{
        width: 50,
        cursor: "pointer",
        color: "white",
        "&:hover, &:focus, &$selected, &$selected:hover": {
            backgroundColor: theme.palette.primary.light,
        }
    },
        selected:{
            backgroundColor: theme.palette.primary.light,
            borderRight: 5,
            borderColor: 'white',
            borderStyle: 'solid',
        },
}),{defaultTheme: theme});

export { useStyles };

