import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    //Header
    header:{
        background: theme.palette.primary.main,
        color: 'white',
        height: 100,
    },
    subheader:{
        variant:"h6",
        align:"center",
        color:"white"
    }
}),{defaultTheme: theme});

export { useStyles };

