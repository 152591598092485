import React from 'react'
import { Button } from "@material-ui/core";

import { useStyles } from "../../styles/ButtonStyles";

interface ButtonStyledProps{
    text:string,
    type?:'button' | 'reset' | 'submit',
    onClick?:any,
    style?:any,
}

export default function ButtonStyled(props: ButtonStyledProps){
    const classes = useStyles();

    return (
        <Button variant="contained" className={classes.button} type={props.type} onClick={props.onClick} style={props.style}>
            {props.text}
        </Button>
    )
}