//inspiration from https://www.youtube.com/watch?v=Jgdx_qykoPw

import React from 'react';

import {useStyles} from "../../styles/LoginStyles"
import Layout from "../../components/Layout";
import SetupForm from "../../components/mfa/SetupForm";


export default function setup() {
    const classes = useStyles();
    return (
        <Layout allowAnonymous={false}>
            <SetupForm/>
        </Layout>
    );
}
