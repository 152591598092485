import React from 'react'
import {CardHeader} from "@material-ui/core";

import { useStyles } from "../../styles/HeaderStyles";
import { classicNameResolver } from 'typescript';

interface HeaderProps{
    title:string,
    titleTypographyProps?:any,
    subheader?:string,
    subheaderTypographyProps?:any,
}

export default function Header( props: HeaderProps){
    const classes = useStyles();

    return (
        <CardHeader className={classes.header} title={props.title} subheader={props.subheader} titleTypographyProps={{variant:"h5", align:"center"}} subheaderTypographyProps={{variant:"h6", align:"center", color:'inherit'}}/>
    )
}