import { makeStyles } from '@material-ui/core/styles';
import theme from "../config/Theme";
import { stylesConfig } from "../config/StylesConfig";

//Since we are using material ui I wanna try useStyles hook
const useStyles = makeStyles(theme => ({
    logoLoginPage:{
        height: 100,
        display: 'block',
        marginTop: 20,
        marginBottom: 50,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    loginRoot:{
        marginTop: 80,
        marginRight: "auto",
        marginLeft: "auto"
    },
    loginContainer:{
        width: "90%",
        maxWidth: 700,
        padding: 10
    },
    formErrors:{
        color: theme.palette.error.main
    },
}),{defaultTheme: theme});

export { useStyles };

